export const navigationRoutes = {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
      },
    },
    {
      name: 'google-maps',
      displayName: 'menu.maps',
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-maps',
      },
    },
    {
      name: 'google-15',
      displayName: 'menu.maps-15',
      meta: {
        iconClass: 'va-icon fa fa-clock-o',
      },
    },
    {
      name: 'google-30',
      displayName: 'menu.maps-30',
      meta: {
        iconClass: 'va-icon fa fa-adjust',
      },
    },

    {
      name: 'checkRoute',
      displayName: 'menu.checkRoute',
      meta: {
        iconClass: 'va-icon fa fa-crosshairs',
      },
    },
    {
      name: 'routes',
      displayName: 'menu.routes',
      meta: {
        iconClass: 'va-icon fa fa-road',
      },
    },
  ],
}
