// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import { ColorThemePlugin } from '../services/vuestic-ui'
import store from '../store/index'
import router from '../router/index'
import { VuesticPlugin } from '../services/vuestic-ui/components'
import '../i18n/index'

import '../metrics'
import '../registerServiceWorker'

import { consoleBuildInfo } from 'vue-cli-plugin-build-info/plugin'
import Antd from 'ant-design-vue/lib/select'
// import 'ant-design-vue/lib/select/style/css'

import ReloadBtn from '../helper/ReloadBtn.vue'
import vaSelectC from '../components/va-select-customize/VaSelectCustomize.vue'

import firebase from 'firebase'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: 'AIzaSyCrbZsdpcms6CcTnQ-C-0l1B18FNSx9odI',
//   authDomain: 'decoded-cove-277222.firebaseapp.com',
//   databaseURL: 'https://decoded-cove-277222.firebaseio.com',
//   projectId: 'decoded-cove-277222',
//   storageBucket: 'decoded-cove-277222.appspot.com',
//   messagingSenderId: '626981699011',
//   appId: '1:626981699011:web:13ddfaaa99603f7055d176',
//   measurementId: 'G-L0K39D8CX4',
// }
const firebaseConfig = {
  apiKey: 'AIzaSyAu4ap54i3anSdml8zZSoq112TY33k7QIw',
  authDomain: 'rcrc-leap.firebaseapp.com',
  projectId: 'rcrc-leap',
  storageBucket: 'rcrc-leap.appspot.com',
  messagingSenderId: '244656347593',
  appId: '1:244656347593:web:b3d2d88cbe3ddf765eb018',
  measurementId: 'G-9RS319B5R9',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

consoleBuildInfo()
var filter = function (text, length, clamp) {
  clamp = clamp || '...'
  return text.length > length ? text.slice(0, length) + clamp : text
}

Vue.filter('truncate', filter)
Vue.use(VuesticPlugin)
Vue.use(Antd)

Vue.component('reload', ReloadBtn)
Vue.component('va-select-c', vaSelectC)

var retrievedObject = localStorage.getItem('themesColors')
if (retrievedObject) {
  Vue.use(ColorThemePlugin, JSON.parse(retrievedObject))
} else {
  Vue.use(ColorThemePlugin, {
    // override colors here.
    primary: '#015F36',
    secondary: '#D2D2D2',
    success: '#40e583',
    info: '#2c82e0',
    danger: '#e34b4a',
    warning: '#ffc200',
    gray: '#828080',
    dark: '#34495e',
    navtext: '#34495e',

  })
  localStorage.setItem('themesColors', JSON.stringify({
    // override colors here.
    primary: '#015F36',
    secondary: '#D2D2D2',
    success: '#40e583',
    info: '#2c82e0',
    danger: '#e34b4a',
    warning: '#ffc200',
    gray: '#828080',
    dark: '#34495e',
    navtext: '#34495e',

  }))
}

router.beforeEach((to, from, next) => {
  // this.ClaearAllToasts()
  const publicPages = ['/auth/login', '/auth/signup', '/auth/recover-password']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  const mustlogin = localStorage.getItem('mustlogin')

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/auth/login')
  } else if (authRequired && loggedIn && mustlogin) {
    next('/auth/recover-password')
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  store.commit('setLoading', false)
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
})
